body {
    margin: 0;
    padding: 0;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    overflow: hidden;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
}

input,
textarea {
    font-family: Roboto, sans-serif;
    font-size: 14px;
}

a {
    cursor: pointer;
    text-decoration: none;
    user-select: none;
}

#root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

#initialLoading {
    font-family: Apercu Pro;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.react-transform-component {
    width: 100% !important;
    height: 100% !important;
}

.react-transform-element {
    width: 100% !important;
    height: 100% !important;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('https://anywhereexpert-static.mysoluto.com/fonts/woff2/ApercuPro-Regular.woff2') format('woff2'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/woff/ApercuPro-Regular.woff') format('woff'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/ttf/ApercuPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('https://anywhereexpert-static.mysoluto.com/fonts/woff2/ApercuPro-Bold.woff2') format('woff2'),
        url('/https://anywhereexpert-static.mysoluto.com/fonts/woff/ApercuPro-Bold.woff') format('woff'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/ttf/ApercuPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('https://anywhereexpert-static.mysoluto.com/fonts/woff2/ApercuPro-Light.woff2') format('woff2'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/woff/ApercuPro-Light.woff') format('woff'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/ttf/ApercuPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('https://anywhereexpert-static.mysoluto.com/fonts/woff2/ApercuPro-LightItalic.woff2') format('woff2'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/woff/ApercuPro-LightItalic.woff') format('woff'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/ttf/ApercuPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('https://anywhereexpert-static.mysoluto.com/fonts/woff2/ApercuPro-Black.woff2') format('woff2'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/woff/ApercuPro-Black.woff') format('woff'),
        url('https://anywhereexpert-static.mysoluto.com/fonts/ttf/ApercuPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
